import { Button, Col, Row, Tag, Input } from "antd"
import React from "react"

export default function InputOne({
  tagName,
  placeholder,
  info,
  setInfo,
  index,
  toggleEdit,
  dataOld,
  name,
  handleSaveInfo,
}) {
  const checkKatana = (text) => {
    const expression = /^[\u{30a0}-\u{30ff}]+$/mu
    const regex = new RegExp(expression)

    if (text?.match(regex)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div
      className="flex items-center py-4"
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
    >
      <Row gutter={6} className="w-full">
        <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
          <Tag color="red">必須</Tag>
          <span className="block ml-2">{tagName}</span>
        </Col>
        <Col className="gutter-row col-center" xl={15} lg={14} md={14}>
          <div className="flex flex-col w-full">
            <Input
              placeholder={placeholder}
              value={info[name]}
              onBlur={(e) => {
                setInfo({ ...info, [name]: e.target.value?.trim() })
              }}
              onChange={(e) => {
                setInfo({ ...info, [name]: e.target.value })
              }}
            />
            {info[name] &&
              tagName == "会社名 カナ" &&
              !checkKatana(info[name]) && (
                <Row className="w-full ">
                  <Col>
                    <div className="error">カタカナを入力してください。</div>
                  </Col>
                </Row>
              )}
            {info[name] &&
              tagName != "会社名 カナ" &&
              info[name]?.trim() == "" && (
                <Row className="w-full ">
                  <Col>
                    <div className="error">空白を入力できません。</div>
                  </Col>
                </Row>
              )}
          </div>
        </Col>
        <Col className="gutter-row col-right" xl={3} lg={4} md={3}>
          <Button
            type={toggleEdit[name] === "保存" ? "primary" : ""}
            danger={toggleEdit[name] === "編集"}
            disabled={
              info[name]
                ? typeof dataOld != "undefined"
                  ? dataOld[name] !== info[name]
                    ? tagName == "会社名 カナ"
                      ? checkKatana(info[name])
                        ? false
                        : true
                      : info[name]?.trim() === ""
                      ? true
                      : false
                    : true
                  : true
                : info[name] === ""
                ? true
                : true
            }
            onClick={() => handleSaveInfo(name, index)}
          >
            {toggleEdit[name]}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
