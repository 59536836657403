import { Button, Col, Select, Input, Row, Tag, message, Spin } from "antd"
import React, { useState, useEffect, useRef, useContext } from "react"
import { dataBank } from "./infoBasic/FakeData"
import callApi from "../../services/api"
import { updateStore } from "../../services/user"
import { AccountContext } from "../../context/account"
import { onKeyPressNumber } from "../../customUtil/global"
const { Option } = Select

function Account() {
  const { user } = useContext(AccountContext)
  const [infoBank, setInfoBank] = useState(dataBank)
  const [dataOld, setDataOld] = useState()
  const [disableBtn, setDisableBtn] = useState(false)
  const [toggleEdit, setToggleEdit] = useState(() => {
    const arr = {}
    // eslint-disable-next-line guard-for-in
    for (const key in dataBank) {
      arr[key] = "保存"
    }
    return arr
  })
  const [errPhoneNumber, setErrorPhoneNumber] = useState({
    branch_number: false,
    account_number: false,
  })

  const success = () => {
    message.success({
      content: "保存しました。",
      className: "custom-class",
    })
  }

  const successAll = () => {
    message.success({
      content: "口座情報を更新しました。",
      className: "custom-class",
    })
  }

  const error = () => {
    message.error({
      content: "正しい情報を入力してください",
      className: "custom-class",
    })
  }

  const hanldeCheckNumber = (str) => {
    const numbers = /^[0-9]+$/
    if (str.length > 0) {
      return !str?.match(numbers)
    }
  }

  const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    const cleaned = ("" + str).replace(/\D/g, "")
    // Check if the input is of correct length
    const match = cleaned?.match(/^(\d{2})(\d{7})$/)
    if (match) {
      return match[1] + "-" + match[2]
    }
    return str
  }

  const handleSaveAllInfo = async () => {
    setDisableBtn(true)
    const data = {}
    // eslint-disable-next-line guard-for-in
    for (const keys in dataBank) {
      data[keys] = infoBank[keys]
    }
    const response = await updateStore(
      user.store_id,
      {
        forEverything: true,
        page: 2,
      },
      { ...data }
    )
    if (response.data) {
      const obj = {}
      for (const key in dataBank) {
        if (data[key]) {
          obj[key] = "編集"
        }
      }
      setDisableBtn(false)
      setToggleEdit({ ...toggleEdit, ...obj })
      successAll()
      setDataOld({ ...data })
    } else {
      error()
      setDisableBtn(false)
    }
  }

  const handleSaveInfobank = (name) => {
    // console.log(infoBank[name])
    callApi.store
      .update({ [name]: infoBank[name] })
      .then(() => {
        success()
        setToggleEdit({ ...toggleEdit, [name]: "編集" })
        setDataOld({ ...dataOld, [name]: infoBank[name] })
      })
      .catch(() => error())
  }

  const accountHolder = useRef()

  useEffect(() => {
    if (infoBank.branch_number) {
      setErrorPhoneNumber({
        ...errPhoneNumber,
        branch_number: hanldeCheckNumber(infoBank.branch_number),
      })
    }
  }, [infoBank.branch_number])

  useEffect(() => {
    if (infoBank.account_number) {
      setErrorPhoneNumber({
        ...errPhoneNumber,
        account_number: hanldeCheckNumber(infoBank.account_number),
      })
    }
  }, [infoBank.account_number])

  useEffect(() => {
    const getBank = async () => {
      try {
        const response = await callApi.store.retrieve()
        if (response.data) {
          setInfoBank(response.data.store)
          setDataOld(response.data.store)
          const obj = {}
          for (const key in dataBank) {
            if (typeof response.data.store[key] === "object") {
              let isNull = false
              for (const i in response.data.store[key]) {
                if (response.data.store[key][i]) {
                  isNull = true
                } else {
                  isNull = false
                }
              }
              if (isNull) {
                obj[key] = "編集"
              }
            } else if (response.data.store[key]) {
              obj[key] = "編集"
            }
          }
          // console.log(obj)
          // console.log(toggleEdit)
          setToggleEdit({ ...toggleEdit, ...obj })
        }
      } catch (error) {
        console.log(error)
      }
    }

    getBank()
  }, [])

  const checkKatana = (text) => {
    const expression = /^[\u{30a0}-\u{30ff}]+$/mu
    const regex = new RegExp(expression)
    if (text?.match(regex)) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {/* {!dataOld && (
        <Alert
          message="エラーが発生しました。"
          description="サーバーに接続できません。"
          type="error"
          showIcon
        />
      )} */}
      {!dataOld && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 60,
          }}
        />
      )}
      {dataOld && (
        <div className="p-20 bg-[#fff]">
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">銀行名</span>
              </Col>
              <Col className="gutter-row col-center" xl={16} lg={14}>
                <div className="w-full">
                  <Input
                    placeholder="みずほ銀行"
                    value={infoBank.bank_name ? infoBank.bank_name : ""}
                    onBlur={(e) =>
                      setInfoBank({
                        ...infoBank,
                        bank_name: e.target.value?.trim(),
                      })
                    }
                    onChange={(e) =>
                      setInfoBank({ ...infoBank, bank_name: e.target.value })
                    }
                  />
                  {infoBank.bank_name && infoBank.bank_name?.trim() == "" && (
                    <Row className="w-full ">
                      <Col>
                        <div className="error">空白を入力できません。</div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={toggleEdit.bank_name === "保存" ? "primary" : ""}
                  danger={toggleEdit.bank_name === "編集"}
                  disabled={
                    infoBank.bank_name
                      ? infoBank.bank_name?.trim() === ""
                        ? true
                        : infoBank.bank_name !== dataOld?.bank_name
                        ? false
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("bank_name")}
                >
                  {toggleEdit.bank_name}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">支店名</span>
              </Col>
              <Col className="gutter-row col-center" xl={16} lg={14}>
                <div className="w-full">
                  <Input
                    placeholder="新宿新都心支店"
                    value={infoBank.branch_name ? infoBank.branch_name : ""}
                    onBlur={(e) =>
                      setInfoBank({
                        ...infoBank,
                        branch_name: e.target.value?.trim(),
                      })
                    }
                    onChange={(e) =>
                      setInfoBank({ ...infoBank, branch_name: e.target.value })
                    }
                  />
                  {infoBank.branch_name && infoBank.branch_name?.trim() == "" && (
                    <Row className="w-full ">
                      <Col>
                        <div className="error">空白を入力できません。</div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={toggleEdit.branch_name === "保存" ? "primary" : ""}
                  danger={toggleEdit.branch_name === "編集"}
                  disabled={
                    infoBank.branch_name
                      ? infoBank.branch_name?.trim() === ""
                        ? true
                        : infoBank.branch_name !== dataOld?.branch_name
                        ? false
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("branch_name")}
                >
                  {toggleEdit.branch_name}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">支店番号</span>
              </Col>
              <Col
                className="gutter-row col-center"
                style={{ display: "block" }}
                xl={16}
                lg={14}
              >
                <Row>
                  <Col className="flex items-center" span={24}>
                    <Input
                      placeholder="62-5411700"
                      value={
                        infoBank.branch_number
                          ? formatPhoneNumber(infoBank.branch_number)
                          : ""
                      }
                      onKeyPress={onKeyPressNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setInfoBank({
                            ...infoBank,
                            branch_number: e.target.value,
                          })
                        }
                      }}
                    />
                  </Col>
                </Row>
                {errPhoneNumber.branch_number && (
                  <Row className="w-full">
                    <Col>
                      <div className="error">
                        正しい電話番号を入力してください
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={toggleEdit.branch_number === "保存" ? "primary" : ""}
                  danger={toggleEdit.branch_number === "編集"}
                  disabled={
                    infoBank.branch_number
                      ? Number.isInteger(infoBank.branch_number * 1)
                        ? infoBank.branch_number.length === 9
                          ? infoBank.branch_number !== dataOld?.branch_number
                            ? false
                            : true
                          : true
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("branch_number")}
                >
                  {toggleEdit.branch_number}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">口座種別</span>
              </Col>
              <Col className="gutter-row col-center" xl={16} lg={14}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  defaultValue={
                    infoBank.account_type ? infoBank.account_type : null
                  }
                  onChange={(value) => {
                    setInfoBank({ ...infoBank, account_type: value })
                  }}
                  placeholder="普通"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value="admin">普通</Option>
                  <Option value="staff_order">当座</Option>
                </Select>
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={toggleEdit.account_type === "保存" ? "primary" : ""}
                  danger={toggleEdit.account_type === "編集"}
                  disabled={
                    infoBank.account_type
                      ? infoBank.account_type === ""
                        ? true
                        : infoBank.account_type !== dataOld?.account_type
                        ? false
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("account_type")}
                >
                  {toggleEdit?.account_type}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">口座番号</span>
              </Col>
              <Col
                className="gutter-row col-center"
                style={{ display: "block" }}
                xl={16}
                lg={14}
              >
                <Row>
                  <Col span={24}>
                    <Input
                      placeholder="11002582"
                      value={
                        infoBank.account_number ? infoBank.account_number : ""
                      }
                      onKeyPress={onKeyPressNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 8) {
                          setInfoBank({
                            ...infoBank,
                            account_number: e.target.value,
                          })
                        } else {
                          accountHolder.current.focus()
                        }
                      }}
                    />
                  </Col>
                </Row>
                {errPhoneNumber.account_number && (
                  <Row className="w-full py-4">
                    <Col>
                      <div className="error">
                        正しい電話番号を入力してください
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={toggleEdit.account_number === "保存" ? "primary" : ""}
                  danger={toggleEdit.account_number === "編集"}
                  disabled={
                    !errPhoneNumber.account_number && infoBank.account_number
                      ? infoBank.account_number.length === 8
                        ? infoBank.account_number !== dataOld?.account_number
                          ? false
                          : true
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("account_number")}
                >
                  {toggleEdit.account_number}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">口座名義</span>
              </Col>
              <Col className="gutter-row col-center" xl={16} lg={14}>
                <div className="w-full">
                  <Input
                    placeholder="山田"
                    ref={accountHolder}
                    value={
                      infoBank.account_holder ? infoBank.account_holder : ""
                    }
                    onBlur={(e) =>
                      setInfoBank({
                        ...infoBank,
                        account_holder: e.target.value?.trim(),
                      })
                    }
                    onChange={(e) =>
                      setInfoBank({
                        ...infoBank,
                        account_holder: e.target.value,
                      })
                    }
                  />
                  {infoBank.account_holder &&
                    infoBank.account_holder?.trim() == "" && (
                      <Row className="w-full ">
                        <Col>
                          <div className="error">空白を入力できません。</div>
                        </Col>
                      </Row>
                    )}
                </div>
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={toggleEdit.account_holder === "保存" ? "primary" : ""}
                  danger={toggleEdit.account_holder === "編集"}
                  disabled={
                    infoBank.account_holder
                      ? infoBank.account_holder?.trim() === ""
                        ? true
                        : infoBank.account_holder !== dataOld?.account_holder
                        ? false
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("account_holder")}
                >
                  {toggleEdit.account_holder}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" span={6}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2 payment-text">口座名義 カナ</span>
              </Col>
              <Col className="gutter-row col-center" xl={16} lg={14}>
                <div className="w-full">
                  <Input
                    placeholder="ヤマダ"
                    value={
                      infoBank.account_name_kana
                        ? infoBank.account_name_kana
                        : ""
                    }
                    onBlur={(e) =>
                      setInfoBank({
                        ...infoBank,
                        account_name_kana: e.target.value?.trim(),
                      })
                    }
                    onChange={(e) =>
                      setInfoBank({
                        ...infoBank,
                        account_name_kana: e.target.value,
                      })
                    }
                  />
                  {infoBank.account_name_kana &&
                    !checkKatana(infoBank.account_name_kana) && (
                      <Row className="w-full ">
                        <Col>
                          <div className="error">
                            カタカナを入力してください。
                          </div>
                        </Col>
                      </Row>
                    )}
                </div>
              </Col>
              <Col className="gutter-row col-right" span={2}>
                <Button
                  type={
                    toggleEdit.account_name_kana === "保存" ? "primary" : ""
                  }
                  danger={toggleEdit.account_name_kana === "編集"}
                  disabled={
                    infoBank.account_name_kana
                      ? infoBank.account_name_kana?.trim() === ""
                        ? true
                        : infoBank.account_name_kana !==
                          dataOld?.account_name_kana
                        ? checkKatana(infoBank.account_name_kana)
                          ? false
                          : true
                        : true
                      : true
                  }
                  onClick={() => handleSaveInfobank("account_name_kana")}
                >
                  {toggleEdit.account_name_kana}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="flex items-center pt-16">
            <Row className="w-full gutter-row justify-center">
              <Col>
                <Button
                  disabled={disableBtn}
                  type="primary"
                  className="w-80"
                  onClick={handleSaveAllInfo}
                >
                  保存する
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

export default Account
