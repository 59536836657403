import { Modal } from "antd"
import React, { useEffect, useState } from "react"
import { openCustomNotificationWithIcon } from "../../../common/notifycation"
import { deleteAccount, getAccount } from "../../../services/account"
import DataTable from "./DataTable"
import HeaderAccountList from "./HeaderAccountList"

export default function AccountList({ isRoot }) {
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [data, setData] = useState([])
  const [totals, setTotals] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    skip: 0,
  })

  const handleOk = async () => {
    setLoading(true)
    const { response } = await deleteAccount(idDelete)

    if (response.status == 200) {
      openCustomNotificationWithIcon(
        "success",
        "notifycation Success",
        "Delete Success."
      )
      setIsModalVisible(false)
      setLoading(false)
      getListAccount()
    } else {
      openCustomNotificationWithIcon(
        "error",
        "notifycation Error",
        "Delete Error."
      )
      setIsModalVisible(false)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    getListAccount()
  }, [params.page])

  const getListAccount = async () => {
    setLoading(true)
    const param = {
      skip: params.page > 1 ? (params.skip = (params.page - 1) * 10) : 0,
      take: 10,
    }
    const { response } = await getAccount(param)

    if (response.status == 200) {
      setTotals(response.data.total)
      const dataTable = []
      response.data.users.map((data) => {
        let roles = ""
        const phoneNumberArray = []
        if (data.role == "admin") {
          roles = data.isRoot ? "マスター管理者" : "管理者"
        } else if (data.role == "developer") {
          roles = "スタッフ（発注可）"
        } else {
          roles = "スタッフ（発注不可）"
        }
        // let phone
        // if (data.metadata?.phone) {
        //   const stringFirst = data.metadata.phone.substr(0, 3)
        //   phoneNumberArray.push(stringFirst)
        //   const stringSecond = data.metadata.phone.substr(3, 4)
        //   phoneNumberArray.push(stringSecond)
        //   const stringThird = data.metadata.phone.substr(7, 4)
        //   phoneNumberArray.push(stringThird)
        //   phone = phoneNumberArray.join("-")
        // }
        dataTable.push({
          key: data.id,
          name: `${data.first_name} ${data.last_name}`,
          email: data.email,
          role: roles,
          phone: data.metadata?.phone,
          isRoot: data.isRoot,
        })
      })
      setData([...dataTable])
      setLoading(false)
    }
  }

  return (
    <div>
      <HeaderAccountList
        isRoot={isRoot}
        setData={setData}
        setLoading={setLoading}
        getListAccount={getListAccount}
        setTotals={setTotals}
      />
      <DataTable
        isRoot={isRoot}
        loading={loading}
        data={data}
        params={params}
        setLoading={setLoading}
        setIsModalVisible={setIsModalVisible}
        setIdDelete={setIdDelete}
        setParams={setParams}
        totals={totals}
      />
      <Modal
        title="アカウント削除"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>このアカウントを削除しますしょうか？</p>
      </Modal>
    </div>
  )
}
