import { Breadcrumb, Button, Tabs } from "antd"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
// import Commercial from "../../customComponents/companyInfo/commercial"
import Account from "../../customComponents/companyInfos/Account"
import InfoBasic from "../../customComponents/companyInfos/infoBasic"
import Payment from "../../domain/paymentManagement/Payment"
import AccountList from "../../customComponents/companyInfos/accountList/AccountList"
import { AccountContext } from "../../context/account"
import ShippingAddressRegistration from "../../domain/superListCompany/superListCompanyDetail/ShippingAddressRegistration"

const { TabPane } = Tabs

const CompanyInfo = ({ location, page }) => {
  const { isRoot, user } = useContext(AccountContext)
  const [keyPage, setKeyPage] = useState("1")
  const [title, setTitle] = useState("企業情報 - 基本情報")
  const hanldeWithDrawMoney = () => {
    navigate("/withdraw-money")
  }
  const onChangePage = (values) => {
    setKeyPage(values)
    switch (values) {
      case "1":
        setTitle("企業情報 - 基本情報")
        break
      case "2":
        setTitle("店舗情報発送先登録")
        break
      case "3":
        setTitle("企業情報 - 口座情報")
        break
      case "4":
        setTitle("企業情報 - 支払い情報")
        break
      case "5":
        setTitle("企業情報 - アカウント一覧")
        break

      default:
        break
    }
  }
  useEffect(() => {
    if (page) {
      setKeyPage(page)
    }

    const params = new URLSearchParams(location.search)
    const tab = params.get("tab")
    if (tab) {
      setKeyPage(tab)
    }
  }, [])
  return (
    <div>
      <Content>
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item route="/home">
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item route="/company-info">
              <Link to="/company-info">企業情報</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex items-center justify-between">
            <Title
              level={3}
              style={{
                marginBottom: "1rem",
                marginTop: "1rem",
                padding: "20px 25px",
              }}
            >
              {title}
            </Title>
            <Button className="mr-10" onClick={hanldeWithDrawMoney}>
              退会申請
            </Button>
          </div>
          <Tabs
            activeKey={keyPage}
            onChange={onChangePage}
            size="large"
            className="companyInfo-tab-pane"
          >
            <TabPane tab="基本情報" key="1">
              <InfoBasic />
            </TabPane>
            <TabPane tab="店舗情報発送先登録" key="2">
              <ShippingAddressRegistration storeId={user?.store_id} />
            </TabPane>
            <TabPane tab="口座情報" key="3">
              <Account />
            </TabPane>
            <TabPane tab="支払い情報" key="4">
              <Payment />
            </TabPane>
            <TabPane tab="アカウント一覧" key="5">
              <AccountList setKeyPage={setKeyPage} isRoot={isRoot} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  )
}
export default CompanyInfo
