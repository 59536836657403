/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Input,
  Row,
  Spin,
  Tag,
  Modal,
  DatePicker,
  Form,
  message,
  Tooltip,
} from "antd"
import React, { useState, useRef, useEffect, useContext } from "react"
import moment from "moment"
import "../../../assets/styles/company-info/infoBasic.scss"
import { fakeDataInfo } from "./FakeData"
import callApi from "../../../services/api"
import GetZipCodeAddress from "../GetZipCodeAddress"
import { AccountContext } from "../../../context/account"
import {
  getInfo,
  hanldeCheckNumber,
  success,
  error,
  handleSaveAllInfo,
} from "./ProcessingFiles"
import InputOne from "./InputOne"
import InputTow from "./InputTow"
import { MinusCircleOutlined } from "@ant-design/icons"

import PhoneInput from "react-phone-number-input/input"
import "react-phone-number-input/style.css"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import { updateStore } from "../../../services/user"
import textAndRules from "../../../utils/textAndRules"

const yearFormat = "YYYY"
const monthFormat = "MM"

function InfoBasic() {
  const [info, setInfo] = useState(fakeDataInfo)
  const [form] = Form.useForm()

  const [arrayNameSaleCompany, setArrayNameSaleCompany] = useState([0])
  const [errPhoneNumber, setErrorPhoneNumber] = useState(true)
  const [errDate, setErrorDate] = useState(false)
  const [dataOld, setDataOld] = useState(null)
  const [loadPage, setLoadPage] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [myIndex, setMyIndex] = useState(0)
  const [disableBtnAll, setDisableBtnAll] = useState(true)
  const [inputPhone, setInputPhone] = useState(null)

  const { user, dispatch, isRoot } = useContext(AccountContext)
  const checkKatana = (text) => {
    const expression = /^[\u{30a0}-\u{30ff}]+$/mu
    const regex = new RegExp(expression)

    if (text?.match(regex)) {
      return true
    } else {
      return false
    }
  }
  const handleOk = () => {
    // setInfo((prev) => {
    //   const newObj = { ...prev.wholesale_companies }
    //   delete newObj[myIndex]
    //   callApi.store.update({ wholesale_companies: newObj })
    //   return { ...prev, wholesale_companies: { ...newObj } }
    // })
    const newObj = { ...info.wholesale_companies }
    delete newObj[myIndex]
    callApi.store
      .update({ wholesale_companies: newObj })
      .then(() =>
        getInfo(
          setInfo,
          setDataOld,
          setArrayNameSaleCompany,
          setToggleEdit,
          toggleEdit
        )
      )
    setArrayNameSaleCompany((prev) => {
      const newCompany = prev.filter((item) => item !== myIndex)
      return newCompany
    })
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    const foundedAt = info.founded_at.year + "" + info.founded_at.month
    setErrorDate(hanldeCheckNumber(foundedAt))
  }, [info.founded_at])

  const [toggleEdit, setToggleEdit] = useState(() => {
    const arr = {}
    // eslint-disable-next-line guard-for-in
    for (const key in fakeDataInfo) {
      if (key === "wholesale_companies") {
        arr[key] = ["保存"]
      } else {
        arr[key] = "保存"
      }
    }
    return arr
  })

  const handleSaveInfo = (name, index) => {
    if (name === "founded_at") {
      const foundedAt = info.founded_at.year + "-" + info.founded_at.month
      callApi.store
        .update({ founded_at: foundedAt })
        .then(() => {
          success()
          setToggleEdit({ ...toggleEdit, [name]: "編集" })
          setDataOld({ ...dataOld, founded_at: { ...info.founded_at } })
        })
        .catch(() => error())
    } else if (name === "phone_company") {
      callApi.store
        .update({ phone_company: info.phone_company })
        .then(() => {
          success()
          setErrorPhoneNumber(true)
          setToggleEdit({ ...toggleEdit, [name]: "編集" })
          setDataOld({ ...dataOld, phone_company: { ...info.phone_company } })
        })
        .catch(() => error())
    } else {
      callApi.store
        .update({
          [name]: info[name],
        })
        .then(() => {
          callApi.store.retrieve().then(({ data }) => {
            // update user infor view after click update company name button
            dispatch({
              type: "setUserInforAfterUpdateCompany",
              payload: data.store,
            })
          })

          if (name === "wholesale_companies") {
            const ws_array = toggleEdit.wholesale_companies.map((item) => {
              return item === "保存" ? "編集" : item
            })
            setToggleEdit({
              ...toggleEdit,
              wholesale_companies: ws_array,
            })
          } else {
            setToggleEdit({ ...toggleEdit, [name]: "編集" })
          }
          success()
          setDataOld({ ...dataOld, [name]: info[name] })
        })
        .catch(() => error())
      // callApi.store
      //   .update({ [name]: info[name] })
      //   .then(() => {
      //     callApi.store.retrieve().then(({ data }) => {
      //       // update user infor view after click update company name button
      //       dispatch({ type: "setUserInfor", payload: data.store })
      //     })

      //     if (name === "wholesale_companies") {
      //       const ws_array = toggleEdit.wholesale_companies.map((item) => {
      //         return item === "保存" ? "編集" : item
      //       })
      //       setToggleEdit({
      //         ...toggleEdit,
      //         wholesale_companies: ws_array,
      //       })
      //     } else {
      //       setToggleEdit({ ...toggleEdit, [name]: "編集" })
      //     }
      //     success()
      //     setDataOld({ ...dataOld, [name]: info[name] })
      //   })
      //   .catch(() => error())
    }
  }

  const checkUrl = (url) => {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    const regex = new RegExp(expression)

    if (url?.match(regex)) {
      return true
    } else {
      return false
    }
  }

  const handleSaveRepName = ({ ...name }, index) => {
    callApi.store
      .update({ ...name })
      .then(() => {
        success()
        setDataOld({ ...dataOld, ...name })
      })
      .catch(() => error())
  }

  const betweenNumberRef = useRef()
  const lastNumberRef = useRef()
  const monthRef = useRef()

  useEffect(() => {
    getInfo(
      setInfo,
      setDataOld,
      setArrayNameSaleCompany,
      setToggleEdit,
      toggleEdit
    )
  }, [loadPage])

  const handleRemoveWholeSale = (index, i) => {
    setIsModalVisible(true)
    setMyIndex(index)
  }

  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split("")
        if (array[1] == "0") {
          setErrorPhoneNumber(true)
          setInfo({ ...info, phone_company: "" })
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        if (array[0] !== inputPhone?.split("")[0]) {
          setErrorPhoneNumber(true)
          setInfo({ ...info, phone_company: "" })

          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        if (array[1] !== inputPhone?.split("")[1]) {
          setErrorPhoneNumber(true)
          setInfo({ ...info, phone_company: "" })

          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        setErrorPhoneNumber(false)
        setInfo({ ...info, phone_company: value })
        return Promise.resolve()
      }
      setErrorPhoneNumber(true)
      setInfo({ ...info, phone_company: "" })

      return Promise.reject(
        new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
      )
    }
    setErrorPhoneNumber(true)
    setInfo({ ...info, phone_company: "" })

    return Promise.reject(new Error(""))
  }

  const handleSaveAllInfo = async () => {
    const data = {}
    for (const key in fakeDataInfo) {
      if (key === "founded_at") {
        data[key] = info?.founded_at.year + "-" + info?.founded_at.month
      } else {
        data[key] = typeof info[key] == "string" ? info[key]?.trim() : info[key]
      }
    }
    if (user.store_id) {
      const response = await updateStore(
        user.store_id,
        {
          forEverything: true,
          page: 1,
        },
        { ...data }
      )
      if (response.data) {
        message.success({
          content: "企業情報を更新しました。",
        })
        setErrorPhoneNumber(true)
        setDataOld({ ...info })
        setLoadPage(new Date().getTime())
      } else {
        error()
      }
    }
  }

  useEffect(() => {
    console.log("info", info)
    if (
      info?.name?.trim() &&
      info?.name_kana?.trim() &&
      info?.address?.num1?.trim() &&
      info?.address?.num2?.trim() &&
      info?.address?.region?.trim() &&
      info?.address?.city?.trim() &&
      info?.address?.town?.trim() &&
      info?.address?.building_name?.trim() &&
      info?.rep_name?.trim() &&
      info?.rep_name_first?.trim() &&
      info?.rep_name_kana?.trim() &&
      info?.rep_name_kana_first?.trim() &&
      info?.phone_company?.trim() &&
      info?.founded_at?.year?.trim() &&
      info?.founded_at?.month?.trim() &&
      info?.company_home_url?.trim() &&
      checkKatana(info?.rep_name_kana) &&
      checkKatana(info?.rep_name_kana_first) &&
      checkKatana(info?.name_kana)
    ) {
      setDisableBtnAll(false)
      return
    }
    setDisableBtnAll(true)
  }, [info])
  return (
    <>
      {/* {!dataOld && (
        <Alert
          message="エラーが発生しました。"
          description="サーバーに接続できません。"
          type="error"
          showIcon
        />
      )} */}
      {!dataOld && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 60,
          }}
        />
      )}
      {dataOld && (
        <div className="p-20 bg-[#fff]">
          <InputOne
            tagName="会社名 ※㈱は不可"
            placeholder="正式名称で記入してください。"
            info={info}
            name="name"
            setInfo={setInfo}
            index={0}
            toggleEdit={toggleEdit}
            dataOld={dataOld}
            handleSaveInfo={handleSaveInfo}
          />
          <InputOne
            tagName="会社名 カナ"
            placeholder="全角カナで入力してください。"
            info={info}
            name="name_kana"
            setInfo={setInfo}
            index={1}
            toggleEdit={toggleEdit}
            dataOld={dataOld}
            handleSaveInfo={handleSaveInfo}
          />
          <GetZipCodeAddress
            info={info}
            setInfo={setInfo}
            setDataOld={setDataOld}
            dataOld={dataOld}
            name={toggleEdit}
            setName={setToggleEdit}
          />
          <InputTow
            tagName={"代表者名"}
            placeholder1={"山田"}
            placeholder2={"浩二"}
            name={"rep_name_first"}
            nameFist={"rep_name"}
            info={info}
            setInfo={setInfo}
            index={2}
            toggleEdit={toggleEdit}
            setToggleEdit={setToggleEdit}
            handleSaveRepName={handleSaveRepName}
            dataOld={dataOld}
          />
          <InputTow
            tagName={"代表者名 カナ"}
            placeholder1={"ヤマダ"}
            placeholder2={"コウジ"}
            name={"rep_name_kana"}
            nameFist={"rep_name_kana_first"}
            info={info}
            setInfo={setInfo}
            index={3}
            toggleEdit={toggleEdit}
            setToggleEdit={setToggleEdit}
            handleSaveRepName={handleSaveRepName}
            dataOld={dataOld}
          />
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2">電話番号</span>
              </Col>
              <Col
                className="gutter-row col-center"
                style={{ display: "block" }}
                xl={15}
                lg={14}
                md={14}
              >
                <Row>
                  <Col className="flex items-center">
                    <Form
                      form={form}
                      initialValues={{ phone: info.phone_company }}
                      onChange={(e) => {
                        if (e.target.id == "phone") {
                          if (e.target.value) {
                            setInputPhone(e.target.value)
                          }
                        }
                      }}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      className="shippingAddressRegistration "
                    >
                      <Form.Item
                        name={"phone"}
                        rules={[
                          {
                            required: true,
                            message: textAndRules.pleaseInput,
                          },
                          {
                            validator: checkInputPhoneNumber,
                          },
                        ]}
                      >
                        <PhoneInput
                          country="JP"
                          placeholder={"000-0000-0000"}
                          className="inputNumberPhone"
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
              <Col
                className="gutter-row col-right al items-center"
                xl={3}
                lg={4}
                md={3}
              >
                <Button
                  type={toggleEdit.phone_company === "保存" ? "primary" : ""}
                  danger={toggleEdit.phone_company === "編集"}
                  onClick={() => handleSaveInfo("phone_company", 4)}
                  disabled={errPhoneNumber}
                >
                  {toggleEdit.phone_company}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2">法人設立年月</span>
              </Col>
              <Col
                className="gutter-row col-center"
                xl={15}
                lg={14}
                md={14}
                style={{ display: "block" }}
              >
                <Row>
                  <Col className="flex items-center">
                    <DatePicker
                      style={{ width: "5rem" }}
                      defaultValue={
                        info.founded_at.year &&
                        moment(info.founded_at.year, yearFormat)
                      }
                      // value={info.founded_at.year}
                      format={yearFormat}
                      picker="year"
                      placeholder="2000"
                      onChange={(date, dateString) => {
                        monthRef.current.focus()
                        setInfo({
                          ...info,
                          founded_at: {
                            ...info["founded_at"],
                            year: dateString,
                          },
                        })
                      }}
                    />
                    {/* <Input
                      style={{ width: "5rem" }}
                      placeholder="2000"
                      value={info.founded_at.year}
                      onBlur={(e) => {
                        setInfo({
                          ...info,
                          founded_at: {
                            ...info["founded_at"],
                            year: e.target.value?.trim(),
                          },
                        })
                      }}
                      onChange={(e) => {
                        if (e.target.value.length === 4) {
                          monthRef.current.focus()
                          setInfo({
                            ...info,
                            founded_at: {
                              ...info["founded_at"],
                              year: e.target.value?.trim(),
                            },
                          })
                        } else {
                          setInfo({
                            ...info,
                            founded_at: {
                              ...info["founded_at"],
                              year: e.target.value,
                            },
                          })
                        }
                      }}
                    /> */}
                    <span className="block mx-2">年</span>
                    <DatePicker
                      style={{ width: "5rem" }}
                      placeholder="1"
                      ref={monthRef}
                      picker="month"
                      defaultValue={
                        info.founded_at.month &&
                        moment(info.founded_at.month, monthFormat)
                      }
                      format={monthFormat}
                      onChange={(date, dateString) => {
                        setInfo({
                          ...info,
                          founded_at: {
                            ...info["founded_at"],
                            month: dateString,
                          },
                        })
                      }}
                    />
                    {/* <Input
                      style={{ width: "5rem" }}
                      placeholder="1"
                      ref={monthRef}
                      value={info.founded_at.month}
                      onBlur={(e) => {
                        setInfo({
                          ...info,
                          founded_at: {
                            ...info["founded_at"],
                            month: e.target.value?.trim(),
                          },
                        })
                      }}
                      onChange={(e) => {
                        if (e.target.value.length <= 2) {
                          setInfo({
                            ...info,
                            founded_at: {
                              ...info["founded_at"],
                              month: e.target.value,
                            },
                          })
                        }
                      }}
                    /> */}
                    <span className="block mx-2">月</span>
                  </Col>
                </Row>
                {errDate && (
                  <Row className="w-full">
                    <Col>
                      <div className="error">年月のみ入力してください</div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col
                className="gutter-row col-right items-center"
                xl={3}
                lg={4}
                md={3}
              >
                <Button
                  type={toggleEdit.founded_at === "保存" ? "primary" : ""}
                  danger={toggleEdit.founded_at === "編集"}
                  onClick={() => handleSaveInfo("founded_at", 5)}
                  disabled={
                    !errDate &&
                    info.founded_at.year.length === 4 &&
                    info.founded_at.month * 1 > 0 &&
                    info.founded_at.month <= 12
                      ? dataOld?.founded_at.year !== info.founded_at.year ||
                        dataOld?.founded_at.month !== info.founded_at.month
                        ? false
                        : true
                      : true
                  }
                >
                  {toggleEdit.founded_at}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row gutter={6} className="w-full">
              <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
                <Tag color="red">必須</Tag>
                <span className="block ml-2">ホームページURL</span>
              </Col>
              <Col className="gutter-row col-center" xl={15} lg={14} md={14}>
                <div className="flex flex-col w-full">
                  <div className="w-full">
                    <Input
                      placeholder="www.nametaro.com"
                      style={{ width: "78%" }}
                      value={info.company_home_url}
                      onBlur={(e) => {
                        setInfo({
                          ...info,
                          company_home_url: e.target.value?.trim(),
                        })
                      }}
                      onChange={(e) => {
                        checkUrl(e.target.value)
                        setInfo({ ...info, company_home_url: e.target.value })
                      }}
                    />
                  </div>
                  {info.company_home_url && !checkUrl(info.company_home_url) && (
                    <Row className="w-full">
                      <Col>
                        <div className="error">
                          ホームページ のリンクを入力してください。
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
              <Col className="gutter-row col-right" xl={3} lg={4} md={3}>
                <Button
                  type={toggleEdit.company_home_url === "保存" ? "primary" : ""}
                  danger={toggleEdit.company_home_url === "編集"}
                  onClick={() => handleSaveInfo("company_home_url", 6)}
                  disabled={
                    info.company_home_url == null ||
                    info.company_home_url === ""
                      ? true
                      : checkUrl(info.company_home_url)
                      ? dataOld?.company_home_url !== info.company_home_url
                        ? false
                        : true
                      : true
                  }
                >
                  {toggleEdit.company_home_url}
                </Button>
              </Col>
            </Row>
          </div>
          {arrayNameSaleCompany.map((index, i) => (
            <div
              key={index}
              className="flex items-center py-4"
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
            >
              <Row gutter={6} className="w-full">
                <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
                  <Tag color="default">任意</Tag>
                  <span className="block ml-2">指定卸会社</span>
                </Col>
                <Col className="gutter-row col-center" xl={15} lg={14} md={14}>
                  <div className="w-full">
                    <Input
                      placeholder="指定卸会社を記入してください。"
                      value={
                        info.wholesale_companies
                          ? info.wholesale_companies[index]
                          : ""
                      }
                      onBlur={(e) => {
                        setInfo({
                          ...info,
                          wholesale_companies: {
                            ...info["wholesale_companies"],
                            [index]: e.target.value?.trim(),
                          },
                        })
                      }}
                      onChange={(e) => {
                        setInfo({
                          ...info,
                          wholesale_companies: {
                            ...info["wholesale_companies"],
                            [index]: e.target.value,
                          },
                        })
                      }}
                    />
                    {info.wholesale_companies &&
                      info.wholesale_companies[index] &&
                      info?.wholesale_companies[index]?.trim() == "" && (
                        <Row className="w-full ">
                          <Col>
                            <div className="error">空白を入力できません。</div>
                          </Col>
                        </Row>
                      )}
                  </div>
                </Col>
                <Col
                  className="gutter-row col-right relative"
                  xl={3}
                  lg={4}
                  md={3}
                >
                  <Button
                    // loading={loadings[7 + index]}
                    type={
                      toggleEdit.wholesale_companies[i] === "保存"
                        ? "primary"
                        : ""
                    }
                    danger={toggleEdit.wholesale_companies[i] === "編集"}
                    onClick={() =>
                      handleSaveInfo("wholesale_companies", 7 + index)
                    }
                    disabled={
                      info.wholesale_companies
                        ? dataOld?.wholesale_companies
                          ? info.wholesale_companies[index]?.trim() === ""
                            ? true
                            : typeof info.wholesale_companies[index] ==
                              "undefined"
                            ? true
                            : dataOld?.wholesale_companies[index] !==
                              info.wholesale_companies[index]
                            ? false
                            : true
                          : info.wholesale_companies[index]?.trim() === ""
                        : true
                    }
                  >
                    {toggleEdit.wholesale_companies[i]}
                  </Button>
                  {arrayNameSaleCompany.length > 1 && (
                    <div
                      className="absolute right-[-30px] cursor-pointer"
                      onClick={() => handleRemoveWholeSale(index, i)}
                    >
                      <MinusCircleOutlined />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          ))}
          <div
            className="flex items-center py-4"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <Row className="gutter-row w-full">
              <Col>
                <Button
                  type="danger"
                  className="w-52 h-3"
                  onClick={() => {
                    setArrayNameSaleCompany([
                      ...arrayNameSaleCompany,
                      new Date().getTime(),
                    ])
                    setToggleEdit({
                      ...toggleEdit,
                      wholesale_companies: [
                        ...toggleEdit.wholesale_companies,
                        "保存",
                      ],
                    })
                  }}
                >
                  {" "}
                  ＋ 指定卸会社を追加
                </Button>
              </Col>
            </Row>
          </div>
          <div className="flex items-center justify-center pt-16">
            <Tooltip
              title={
                disableBtnAll ? "必須フィールドに入力してください。" : null
              }
            >
              <Button
                disabled={disableBtnAll}
                type="primary"
                className="!w-[320px] fix_button"
                onClick={handleSaveAllInfo}
              >
                更新する
              </Button>
            </Tooltip>
          </div>
          <Modal
            centered
            title="本当にこの会社を削除しますか?"
            visible={isModalVisible}
            onOk={() => handleOk()}
            onCancel={handleCancel}
          ></Modal>
        </div>
      )}
    </>
  )
}

export default InfoBasic
