import { Col, Row, Tag, Input, Button } from "antd"
import React from "react"

export default function InputTow({
  tagName,
  placeholder1,
  placeholder2,
  name,
  nameFist,
  info,
  setInfo,
  index,
  toggleEdit,
  setToggleEdit,
  handleSaveRepName,
  dataOld,
}) {
  const checkKatana = (text) => {
    const expression = /^[\u{30a0}-\u{30ff}]+$/mu
    const regex = new RegExp(expression)

    if (text?.match(regex)) {
      return true
    } else {
      return false
    }
  }

  const checkValidate = () => {
    if (info[nameFist] || info[name]) {
      if (tagName == "代表者名 カナ") {
        if (!checkKatana(info[nameFist]) || !checkKatana(info[name])) {
          return true
        }
      }
    }
    return false
  }

  const checkValidate2 = () => {
    if (info[nameFist] || info[name]) {
      if (tagName != "代表者名 カナ") {
        if (info[nameFist]?.trim() == "" || info[name]?.trim() == "") {
          return true
        }
      }
    }
    return false
  }

  return (
    <div
      className="flex items-center py-4"
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
    >
      <Row gutter={6} className="w-full">
        <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
          <Tag color="red">必須</Tag>
          <span className="block ml-2">{tagName}</span>
        </Col>
        <Col className="gutter-row col-center" xl={15} lg={14} md={14}>
          <div className="flex flex-col w-full">
            <div className="w-full">
              <Input
                placeholder={placeholder1}
                style={{ width: "35%" }}
                value={info[nameFist] ? info[nameFist] : ""}
                onBlur={(e) => {
                  setInfo({
                    ...info,
                    [nameFist]: e.target.value?.trim(),
                  })
                }}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    [nameFist]: e.target.value,
                  })
                }}
              />
              <Input
                placeholder={placeholder2}
                style={{ width: "35%", marginLeft: "2rem" }}
                value={info[name] ? info[name] : ""}
                onBlur={(e) => {
                  setInfo({
                    ...info,
                    [name]: e.target.value?.trim(),
                  })
                }}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    [name]: e.target.value,
                  })
                }}
              />
            </div>
            {checkValidate() && (
              <Row className="w-full">
                <Col>
                  <div className="error">カタカナを入力してください。</div>
                </Col>
              </Row>
            )}
            {checkValidate2() && (
              <Row className="w-full">
                <Col>
                  <div className="error">空白を入力できません。</div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Col className="gutter-row col-right" xl={3} lg={4} md={3}>
          <Button
            type={toggleEdit[name] === "保存" ? "primary" : ""}
            danger={toggleEdit[name] === "編集"}
            onClick={() => {
              setToggleEdit({
                ...toggleEdit,
                [name]: "編集",
                [nameFist]: "編集",
              })
              handleSaveRepName(
                {
                  [name]: info[name],
                  [nameFist]: info[nameFist],
                },
                index
              )
            }}
            disabled={
              info[nameFist] && info[name]
                ? dataOld?.rep_name
                  ? dataOld[nameFist] !== info[nameFist] ||
                    dataOld[name] !== info[name]
                    ? checkValidate2()
                      ? true
                      : checkValidate()
                    : true
                  : checkValidate2()
                  ? true
                  : checkValidate()
                : true
            }
          >
            {toggleEdit[name]}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
