import callApi from "../../../services/api"
import { fakeDataInfo } from "./FakeData"
import { message } from "antd"
import { updateStore } from "../../../services/user"

export const getInfo = async (
  setInfo,
  setDataOld,
  setArrayNameSaleCompany,
  setToggleEdit,
  toggleEdit
) => {
  try {
    const response = await callApi.store.retrieve()
    if (response.data.store.founded_at !== null) {
      const date = new Date(response.data.store.founded_at)

      response.data.store.founded_at = {
        year: date.getFullYear() + "",
        month: date.getMonth() + 1 + "",
      }
    } else {
      response.data.store.founded_at = {
        year: "",
        month: "",
      }
    }

    if (!response.data.store.address) {
      response.data.store.address = {
        num1: "",
        num2: "",
        region: "",
        city: "",
        town: "",
        building_name: "",
      }
    }

    setInfo(response.data?.store)
    setDataOld(response.data?.store)
    setArrayNameSaleCompany(() => {
      if (response.data?.store.wholesale_companies) {
        const array = Object.entries(response.data.store.wholesale_companies)
        const length = array.length === 0 ? 1 : array.length
        const arr = Array.from(
          new Array(length),
          (_, i) => Object.keys(response.data.store.wholesale_companies)[i]
        )
        return arr
      }
      return Array.from(new Array(1), (_, i) => new Date().getTime())
    })
    const obj = {}
    for (const key in fakeDataInfo) {
      if (key === "wholesale_companies") {
        const ws_array = []
        if (response.data.store[key] !== null) {
          if (Object.entries(response.data.store[key]).length > 0) {
            for (const i in response.data.store[key]) {
              if (response.data.store[key][i]) {
                ws_array.push("編集")
              } else {
                ws_array.push("保存")
              }
            }
          } else {
            ws_array.push("保存")
          }
        } else {
          ws_array.push("保存")
        }

        obj[key] = ws_array
      } else if (typeof response.data.store[key] === "object") {
        let isNull = false
        for (const i in response.data.store[key]) {
          if (response.data.store[key][i]) {
            isNull = true
          } else {
            isNull = false
          }
        }
        if (isNull) {
          obj[key] = "編集"
        }
      } else if (response.data.store[key]) {
        obj[key] = "編集"
      }
    }
    setToggleEdit({ ...toggleEdit, ...obj })
  } catch (error) {
    console.log(error)
  }
}

export const hanldeCheckNumber = (str) => {
  const numbers = /^[0-9]+$/
  if (str.length > 0) {
    return !str?.match(numbers)
  }
}

export const success = () => {
  message.success({
    content: "保存しました。",
  })
}

export const successAll = () => {
  message.success({
    content: "企業情報を更新しました。",
  })
}

export const error = () => {
  message.error({
    content: "正しい情報を入力してください",
    className: "custom-class",
  })
}

export const handleSaveAllInfo = async (
  user,
  info,
  setDataOld,
  setLoadPage
) => {
  const data = {}
  for (const key in fakeDataInfo) {
    if (key === "founded_at") {
      data[key] = info?.founded_at.year + "-" + info?.founded_at.month
    } else {
      data[key] = typeof info[key] == "string" ? info[key]?.trim() : info[key]
    }
  }
  if (user.store_id) {
    const response = await updateStore(
      user.store_id,
      {
        forEverything: true,
        page: 1,
      },
      { ...data }
    )
    if (response.data) {
      message.success({
        content: "企業情報を更新しました。",
      })
      setDataOld({ ...info })
      setLoadPage(new Date().getTime())
    } else {
      error()
    }
  }
}
