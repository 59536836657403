import { Button, Col, Row, Select, Space, Input, Modal } from "antd"
import React, { useState } from "react"
import { Link } from "gatsby"
import { openCustomNotificationWithIcon } from "../../../common/notifycation"
import { searchAccount } from "../../../services/account"

export default function HeaderAccountList({
  isRoot,
  setLoading,
  setData,
  getListAccount,
  setTotals,
}) {
  const [search, setSearch] = useState("")

  const handleChangeSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleSearch = async () => {
    if (search) {
      setLoading(true)
      const { response } = await searchAccount({ q: search })
      if (response.status == 200) {
        setTotals(response.data.total)
        const dataTable = []
        response.data.users.map((data) => {
          let roles = ""
          const phoneNumberArray = []
          if (data.role == "admin") {
            roles = "管理者"
          } else if (data.role == "developer") {
            roles = "スタッフ（発注可）"
          } else {
            roles = "スタッフ（発注不可）"
          }
          let phone
          if (data.metadata?.phone) {
            const stringFirst = data.metadata.phone.substr(0, 3)
            phoneNumberArray.push(stringFirst)
            const stringSecond = data.metadata.phone.substr(3, 4)
            phoneNumberArray.push(stringSecond)
            const stringThird = data.metadata.phone.substr(7, 4)
            phoneNumberArray.push(stringThird)
            phone = phoneNumberArray.join("-")
          }
          dataTable.push({
            key: data.id,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            role: roles,
            phone: phone,
          })
        })
        setData([...dataTable])
        setLoading(false)
      }
    } else {
      getListAccount()
    }
  }

  return (
    <div>
      <Row className="bg-slate-100 p-4" justify="space-between">
        <Col span={8}></Col>
        <Col
          span={9}
          offset={7}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Space>
            <Link to="/company-info/register/handleCompanyInfo/create">
              <Button
                type="danger"
                style={{
                  width: "10rem",
                }}
                disabled={!isRoot}
              >
                ＋ アカウント追加
              </Button>
            </Link>

            <Input
              placeholder="氏名で探す"
              value={search}
              onChange={handleChangeSearch}
            />
            <Button danger onClick={handleSearch} style={{ width: 84 }}>
              検索
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  )
}
