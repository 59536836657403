import React from "react"
import { Button, Space, Table } from "antd"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { navigate } from "gatsby"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"

export default function DataTable({
  isRoot,
  loading,
  data,
  setIsModalVisible,
  setIdDelete,
  totals,
  params,
  setParams,
}) {
  // console.log("isRoot", isRoot)
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <>
          <Space>
            <Button style={{ borderRadius: "2px" }}>
              <a>
                <DoubleLeftOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
              </a>
            </Button>
            <Button style={{ borderRadius: "2px" }}>
              <a className="font-semibold">前のページ</a>
            </Button>
          </Space>
        </>
      )
    }

    if (type === "next") {
      return (
        <>
          <Space>
            <Button style={{ borderRadius: "2px" }}>
              <a className="font-semibold">次のページ</a>
            </Button>
            <Button style={{ borderRadius: "2px" }}>
              <a>
                <DoubleRightOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
              </a>
            </Button>
          </Space>
        </>
      )
    }

    return originalElement
  }
  const columns = [
    {
      title: "氏名",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "電話番号",
      dataIndex: "phone",
      width: "15%",
      render: (_, record) => {
        return <div>{formatPhoneNumber(record.phone)}</div>
      }
    },
    {
      title: "メールアドレス",
      dataIndex: "email",
    },
    {
      title: "権限",
      dataIndex: "role",
      width: "10%",
      render: (text) => (
        <>
          <Button
            style={{
              color:
                text == "マスター管理者"
                  ? "#FB053B"
                  : text == "管理者"
                    ? "#FA7743"
                    : text == "スタッフ（発注可）"
                      ? "#2F54EB"
                      : "#9064C0",
              backgroundColor:
                text == "マスター管理者"
                  ? "rgb(255, 240, 240)"
                  : text == "管理者"
                    ? "rgb(255, 239, 224)"
                    : text == "スタッフ（発注可）"
                      ? "#F0F5FF"
                      : "#F9F0FF",
              width: "100%",
            }}
          >
            {text.toUpperCase()}
          </Button>
        </>
      ),
    },
    {
      title: "操作",
      width: "5%",
      render: (record) => {
        // console.log(record)
        return (
          <Space>
            <Button
              disabled={!isRoot}
              danger
              onClick={() =>
                navigate(
                  `/company-info/register/handleCompanyInfo/${record.key}`
                )
              }
            >
              編集
            </Button>
            <Button
              disabled={!isRoot || record.isRoot}
              style={{
                border: "1px solid rgba(34, 34, 34, 0.7)",
                color: "rgba(34, 34, 34, 0.7)",
              }}
              onClick={() => handleDelete(record.key)}
            >
              削除
            </Button>
          </Space>
        )
      },
    },
  ]
  const handleDelete = (id) => {
    setIdDelete(id)
    setIsModalVisible(true)
  }
  const onChangPage = (value) => {
    setParams({ ...params, page: value })
    // console.log(value)
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={data.key}
        pagination={{
          itemRender: itemRender,
          total: totals,
          position: ["bottomCenter"],
          showSizeChanger: false,
          onChange: onChangPage,
          current: params.page,
          showTotal: (totals, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {totals}件)
            </div>
          ),
        }}
      />
    </div>
  )
}
