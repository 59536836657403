import { Button, Col, Input, Row, Tag, message, Select } from "antd"
import React, { useState, useRef, useEffect } from "react"
import callApi from "../../services/api"
import axios from "axios"
import { getRegion } from "../../services/apiProduct"

export default function GetZipCodeAddress({
  info,
  setInfo,
  dataOld,
  setDataOld,
  name,
  setName,
}) {
  const [errorAddress, setErrorAddress] = useState(false)
  const [errorZipCode, setErrorZipCode] = useState(false)
  const [loadings, setLoadings] = useState([])
  const [region, setRegion] = useState([])

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
  }

  const exitLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = false
      return newLoadings
    })
  }

  const success = () => {
    message.success({
      content: "保存しました。",
      className: "custom-class",
    })
  }

  const error = () => {
    message.error({
      content: "正しい情報を入力してください",
      className: "custom-class",
    })
  }

  const handleSaveAddress = (index) => {
    enterLoading(index)
    callApi.store
      .update({ address: { ...info["address"] } })
      .then(() => {
        setErrorAddress(false)
        success()
        exitLoading(index)
        setName({
          ...name,
          address: "編集",
        })
        setDataOld({ ...dataOld, address: { ...info.address } })
      })
      .catch(() => {
        setErrorAddress(true)
        error()
      })
  }

  const handleGetRegion = () => {
    const api = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${info?.address?.num1}${info?.address?.num2}`
    axios
      .get(api)
      .then((response) => {
        setErrorZipCode(false)
        setInfo({
          ...info,
          address: {
            ...info.address,
            region: response.data.results[0].address1,
            city: response.data.results[0].address2,
            town: response.data.results[0].address3,
          },
        })
        buildingNameAddressRef.current.focus()
      })
      .catch((err) => setErrorZipCode(true))
  }

  useEffect(() => {
    ;(async function () {
      try {
        const { response } = await getRegion()
        if (response.status) {
          setRegion(response.data?.regions)
        }
      } catch (error) {
        throw new Error(error.toString())
      }
    })()
  }, [])

  const buildingNameAddressRef = useRef()
  const numberAddressRef = useRef()
  return (
    <div
      className="flex items-center py-4"
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
    >
      <Row gutter={6} className="w-full">
        <Col className="gutter-row col-text" xl={6} lg={6} md={7}>
          <Tag color="red">必須</Tag>
          <span className="block ml-2">住所</span>
        </Col>
        <Col xl={15} lg={14} md={14}>
          <Row className="w-full py-2">
            <Col
              className="gutter-row col-center"
              span={24}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="block mr-2">〒</span>
              <Input
                type="number"
                placeholder="000"
                style={{ width: "5rem" }}
                value={info.address ? info.address.num1 : ""}
                onBlur={(e) => {
                  setInfo({
                    ...info,
                    address: {
                      ...info.address,
                      num1: e.target.value?.trim(),
                    },
                  })
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 3) {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        num1: e.target.value,
                      },
                    })
                  }
                  if (e.target.value.length === 3) {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        num1: e.target.value?.trim(),
                      },
                    })
                    numberAddressRef.current.focus()
                  }
                }}
              />
              <span className="block mx-2">－</span>
              <Input
                type="number"
                placeholder="0001"
                style={{ marginRight: "1rem", width: "5rem" }}
                ref={numberAddressRef}
                value={info.address ? info.address.num2 : ""}
                onBlur={(e) => {
                  setInfo({
                    ...info,
                    address: {
                      ...info.address,
                      num2: e.target.value?.trim(),
                    },
                  })
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 4) {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        num2: e.target.value,
                      },
                    })
                  }
                }}
              />
              <Button className="btnGetAddress" onClick={handleGetRegion}>
                住所表示
              </Button>
            </Col>
          </Row>
          {errorZipCode && (
            <Row>
              <Col>
                <div className="error">
                  郵便番号から住所が見つかりませんでした。
                </div>
              </Col>
            </Row>
          )}
          <Row className="w-full py-2">
            <Col span={12} className="gutter-row col-center">
              <Select
                // style={{ width: "93%" }}
                className="w-full"
                showSearch
                placeholder="都道府県"
                value={info.address?.region ? info.address?.region : null}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    address: {
                      ...info.address,
                      region: e,
                    },
                  })
                }}
              >
                {region?.map((item) => (
                  <Select.Option key={item?.id} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={12} className="gutter-row col-center">
              <div>
                <Input
                  // style={{ width: "93%" }}
                  className="w-full"
                  placeholder="市区町村"
                  value={info?.address?.city ? info?.address?.city : ""}
                  onBlur={(e) => {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        city: e.target.value?.trim(),
                      },
                    })
                  }}
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        city: e.target.value,
                      },
                    })
                  }}
                />
                {info?.address?.city && info?.address?.city?.trim() == "" && (
                  <Row className="w-full ">
                    <Col>
                      <div className="error">空白を入力できません。</div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row className="w-full py-2">
            <Col span={24} className="gutter-row col-center">
              <div className="w-full">
                <Input
                  // style={{ width: "97%" }}
                  placeholder="町域・番地"
                  value={info?.address?.town ? info?.address?.town : ""}
                  onBlur={(e) => {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        town: e.target.value?.trim(),
                      },
                    })
                  }}
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        town: e.target.value,
                      },
                    })
                  }}
                />
                {info?.address?.town && info?.address?.town?.trim() == "" && (
                  <Row className="w-full ">
                    <Col>
                      <div className="error">空白を入力できません。</div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row className="w-full py-2">
            <Col span={24} className="gutter-row col-center">
              <div className="w-full">
                <Input
                  ref={buildingNameAddressRef}
                  // style={{ width: "97%" }}
                  placeholder="建物名"
                  value={
                    info?.address?.building_name
                      ? info?.address?.building_name
                      : ""
                  }
                  onBlur={(e) => {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        building_name: e.target.value?.trim(),
                      },
                    })
                  }}
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      address: {
                        ...info.address,
                        building_name: e.target.value,
                      },
                    })
                  }}
                />
                {info?.address?.building_name &&
                  info?.address?.building_name?.trim() == "" && (
                    <Row className="w-full ">
                      <Col>
                        <div className="error">空白を入力できません。</div>
                      </Col>
                    </Row>
                  )}
              </div>
            </Col>
          </Row>
          {errorAddress && (
            <Row className="w-full py-2">
              <Col>
                <div className="error">十分な情報を入力してください</div>
              </Col>
            </Row>
          )}
        </Col>
        <Col className="gutter-row col-right items-center" xl={3} lg={4} md={3}>
          <Button
            loading={loadings[0]}
            type={name?.address === "保存" ? "primary" : ""}
            danger={name?.address === "編集"}
            onClick={() => handleSaveAddress(0)}
            disabled={
              info?.address
                ? info?.address
                  ? dataOld?.address
                    ? info.address.num1 !== dataOld.address.num1 ||
                      info.address.num2 !== dataOld.address.num2 ||
                      info.address.region !== dataOld.address.region ||
                      info.address.city !== dataOld.address.city ||
                      info.address.town !== dataOld.address.town ||
                      info.address.building_name !==
                        dataOld.address.building_name
                      ? info.address.num1 !== "" &&
                        info.address.num2 !== "" &&
                        info.address.region !== "" &&
                        info.address.city !== "" &&
                        info.address.town !== ""
                        ? false
                        : true
                      : true
                    : info.address.num1 !== "" &&
                      info.address.num2 !== "" &&
                      info.address.region !== "" &&
                      info.address.city !== "" &&
                      info.address.town !== ""
                    ? false
                    : true
                  : true
                : true
            }
          >
            {name?.address}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
